import React, { Component } from "react"

import Input from "src/components/forms/Input"
import Textarea from "src/components/forms/Textarea"
import "./Contact.scss"

class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        name: "",
        email: "",
        message: "",
        companysize: "",
      },
      formErrors: {
        name: "",
        email: "",
        message: "",
        companysize: "",
      },
      hasSubmitted: false,
      disabled: false,
      nameValid: false,
      emailValid: false,
      messageValid: false,
      formValid: false,
      anonymousId: ""
    }
  }

  componentDidMount() {
    const anonId = typeof window.analytics.user === "function" ? window.analytics.user().anonymousId() : ""
    this.setState({ anonymousId: anonId })
  }

  validateFields = () => {
    const { formData, formErrors } = this.state
    const fieldValidationErrors = formErrors

    const nameValid = formData.name.length > 0
    fieldValidationErrors.name = nameValid ? "" : "Please enter your name"

    const emailValid = formData.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    fieldValidationErrors.email = emailValid ? "" : "Please enter a valid email"

    const messageValid = formData.message.length > 0
    fieldValidationErrors.message = messageValid ? "" : "Please enter a message"

    this.setState({ 
      formErrors: fieldValidationErrors,
      nameValid,
      emailValid: Boolean(emailValid),
      messageValid,
    }, () => this.validateForm())

    return nameValid && emailValid && messageValid
  }

  validateForm = () => {
    const { nameValid, emailValid, messageValid } = this.state
    this.setState({ formValid: nameValid && emailValid && messageValid })
  }

  handleChange = e => {
    const { formData, hasSubmitted } = this.state
    const { name, value } = e.target
    this.setState({
      formData: {
        ...formData,
        [name]: value
      }
    }, () => { if(hasSubmitted) this.validateFields() })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.setState({ hasSubmitted: true })
    if(this.validateFields()) {
      const form = e.target
      window.analytics.track("Contact", {
        message: form.message.value,
      })
      form.submit()
    }
  }

  render() {
    const { disabled, formData, formErrors, hasSubmitted, formValid, anonymousId } = this.state
    const { message, companysize, email, name } = formData

    const disabledSubmit = hasSubmitted && !formValid

    return (
      <div className="contact-form">
        <form 
          name="contact" 
          method="POST" 
          data-netlify="true" 
          netlify-honeypot="companysize" 
          action="/thanks"
          onSubmit={this.handleSubmit}
        >
          <div className="hidden">
            <label htmlFor="companysize">Don&apos;t fill this out</label>
            <input 
              type="text" 
              name="companysize" 
              id="companysize" 
              value={companysize}
              onChange={this.handleChange}
              className="form-control form-control-lg" 
            />
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" name="user" value={anonymousId} />
          </div>   
          <div className="row">
            <div className="form-group col-md-6">
              <Input
                label="Your Name *"
                name="name"
                onChange={this.handleChange}
                value={name}
                disabled={disabled}
                className="form-control form-control-lg"
                error={formErrors.name}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <Input
                label="Email *"
                type="email"
                onChange={this.handleChange}
                name="email"
                value={email}
                disabled={disabled}
                className="form-control form-control-lg"
                error={formErrors.email}
              />
            </div>
          </div>
          
          <div className="form-group">
            <Textarea
              label="Your Message *"
              onChange={this.handleChange}
              name="message"
              value={message}
              disabled={disabled}
              className="form-control form-control-lg"
              error={formErrors.message}
            />
          </div>
          
          <button 
            type="submit" 
            className="btn btn-primary btn-lg"
            disabled={disabledSubmit}
          >
            Send Message
          </button>
        </form>
      </div>
    )
  }
}

export default ContactForm
