import React from "react"
import { Link } from "gatsby"

import Layout from "src/components/Layout"
import PageHero from "src/components/PageHero"
import ContactForm from "src/components/forms/Contact"
import "./contact.scss"

const ContactPage = () => (
  <Layout meta={{
    title: "Contact Us",
    description: "How can we help?",
  }}>
    <PageHero
      title="Contact Us"
      staticImage="/img/page-contact-hero.png"
      imagePosition="bottom"
    />
    <div className="container">
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <ContactForm />

          <div className="visit-us">
            <h2>Office Location</h2>

            <div className="row">
              <div className="col-md-6">
                <Link to="/locations/raleigh">
                  <h3>North Carolina</h3>
                </Link>
                <p>
                  400 W. Morgan St
                  <br />
                  Suite 204
                  <br />
                  Raleigh, NC 27603
                  <br />
                  (919) 827-4061
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ContactPage
